/*-----------------------------------------------------------*/
// login
.text-align-custom{text-align: center;}

.text-dashboard{font-size: 1.4em;}

.img-info-product{
  max-width: 50px;
  & img{width: 100%;}
}

.content-info-product{
  max-width: calc(100% - 95px);
}
