/*-----------------------------------------------------------*/
/*media*/
@media (min-width:22.563em) {
  // nav
  .voci-menu{width: 80%;}
}

/* Small devices (landscape phones, 34em and up)*/
@media (min-width: 34em) {
  // nav
  .voci-menu{width: 60%;}
}

@media (min-width: 36em) {
  // home
  .header-text{
    top: 15%;
    // left: 0;
    // transform: translateX(-1.5rem);
    & h2{font-size: 5.5em}
  }

  // product
  #products .card{width: 50%}
}

/* Small devices (tablets, 48em and up)*/
@media (min-width: 48em) {
  // generale
  body{font-size: 12pt;}
  .text-align-custom{text-align: left;}

  // nav
  .voci-menu{
    width: 40%;
    top: 93px;
    height: calc(100% - 93px);
  }
  #searchDiv{
    position: relative;
    width: 100%;
    transform: translate(-40px, 0);
    background-color: transparent;
  }
  .navbar #searchDiv,
  .navbar.affix #searchDiv{top:0;}

  // product
  .info-text-product .nome-prodotto{font-size: 1.8em;}
  #products .card{width: 33.33%;}
  .rif-prodotto{text-align: left;}
  .box-gallery-product .gallery-thumbs{width: 80px}
  .box-gallery-product .gallery-top{
    margin-left: 90px;
    width: calc(100% - 90px)
  }
  .cta-lista{background-color: transparent;}
  .sorting{
    max-width: 200px;
    position: relative;
  }
  .bTn-plugin-sort, .menu-plugin-sort{font-size: 0.9em;}
  .menu-plugin-sort{
    top: 28px;
    left: 0;
    right: 0;
  }
  .row-lista{
    position: relative;
    min-height: 60vh;
  }
  .menu-plugin-filter{
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    transition: .6s ease;
    width: 25%;
    position: absolute;
    padding-top: 0;
    background-color: transparent;
    top: 58px;
    overflow: scroll;
  }
  .div-plugin-filter{
    height: auto;
    overflow: auto;
  }
  .content-bTn-plugin-filter{
    position: relative;
    bottom: auto;
    left: auto;
    background-color: transparent;
    border-top: hidden;
    padding: 0;
  }
  #products-search{
    position: absolute;
    top: 0;
    left: 0;
    max-width: 240px
  }

  // box
  .titolo-box{font-size: 2.5em;}
  .img-block{
    height:60vh;
    min-height:480px;
  }

  // home
  .header-img-new{
    height: 60vh;
    min-height:700px;
    max-height: 800px;
  }
  .header-text{
    top: 20%;
    left: 4rem;
    // transform: translateX(-40%);
  }
  .logo-box-newsletter{height: 80px;}
  .titolo-box-newsletter{font-size: 3.4em;}
  /*box glitter*/
  .center-box-r, .center-box-l{
    bottom: auto;
    left: auto;
    width: auto;
  }
  .center-box-r{
    top: 50%;
    right: 0;
    transform: translate(80%, -50%);
  }
  .center-box-r .sub-box{text-align: right;}
  .center-box-l{
    top: 50%;
    left: 0;
    transform: translate(-85%, -50%);
    text-align: right;
  }
  .text-v{
    position: absolute;
    top: 0;
    left: 1rem;
    transform: translateY(-50%);
  }

  // dashboard
  .text-dashboard{font-size: 2.4em;}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 62em) {
  // nav
  .top-nav{border-bottom: hidden;}
  .voci-menu{
    transform: translateX(0);
    position: relative;
    top: auto;
    width: 100%;
    visibility: visible;
    background-color: transparent;
    box-shadow: none;
    height: auto;
  }
  .voci-menu .content-menu{
    padding: 0;
    text-align: center;
    overflow: auto;
  }
  .voci-menu .content-menu .item-menu{
    border-bottom: hidden;
    text-transform: uppercase;
    width: auto;
  }
  .content-menu>div{margin-bottom: 0rem}
  .content-menu>div>.item-menu{position: inherit;}
  .sot-cat{
    position: absolute;
    border-bottom: 1px solid #EFC8CA;
    transform: translateY(-50px);
    top: 42px;
    bottom: auto;
    padding: 2rem 0 2rem 4rem;
    min-width: 768px;
  }
  .sot-cat.on{position: absolute;}
  .nav-grid{
    display: grid;
    grid-template-columns: repeat(10,minmax(80px,10%));
    grid-auto-rows: auto;
    grid-column-gap: 1.5rem;
    justify-content: center;
  }
  .item{
    grid-column: span 2;
  }
  .item.item-single{
    grid-column: span 5;
  }
  .single{
    column-count: 2;
    column-gap: 15px;
  }
  .ui.pointing.dropdown>.menu:after{
    left: 1.4rem;
    right: auto;
  }

  // home
  .text-v{left: 10%;}

  // product
  #products .card{margin-bottom: 3.5rem;}
  .category-box:nth-child(3n + 2), .category-box:nth-child(3n + 3){
    width: 50%;
    height: calc(50vw - 5vw);
    margin-left: 0;
  }
  .category-box:nth-child(3n + 2) a{padding-right: 5vw}
  .category-box:nth-child(3n + 3) a{padding-left: 5vw}

  .category-box:nth-child(3n + 2) .center-box,
  .category-box:nth-child(3n + 3) .center-box{
    transform: translateX(-50%);
    text-align: center;
  }
  .category-box:nth-child(3n + 2) .center-box{right: 50%;}
  .category-box:nth-child(3n + 3) .center-box{left: 50%;}
  .category-box:nth-child(3n+1) {
    max-height: 540px;
    height: 55vh;
  }
  .category-box:nth-child(3n+1) .center-box{
    top: 4rem;
    left: calc(4rem + 15px);
  }

  // cart
  .img-info-product{max-width: 80px;}

  // footer
  .cta-dropdown-footer{
    border: hidden;
    padding: 0;
    &::before{
      content: "";
      opacity: 0;
    }
  }
  .content-dropdown-footer{
    visibility: visible;
    height: auto;
    opacity: 1;
    border: hidden;
  }
}

/* Large devices (desktops, 1200px and up) */
@media (min-width: 75em) {
  .sot-cat{padding: 2rem 0 2rem 15%;}
}

/* Extra large devices (large desktops, 1900px and up) */
@media (min-width: 100em) {
  #products .card{width: 25%;}
}

/* Extra large devices (large desktops, 2150px and up) */
@media (min-width: 134.35em) {

}

/* Extra large devices (large desktops, 2240px and up) */
@media (min-width: 140em) {

}

@media (min-width: 1441px){
  .main, #products{
    margin-left: auto;
    margin-right: auto;
    max-width: 1312px;
  }
}
