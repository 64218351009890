$nero:     #000000 !default;
$rosa-l1:  #E9C9CB !default;
$rosa-l2:  #F5DCDF !default;
$rosa-l3:  #F7EFF0 !default;
$ev:       #d6548e !default;

$colors: map-merge(
  (
    "nero":       $nero,
    "rosa-l1":    $rosa-l1,
    "rosa-l2":    $rosa-l2,
    "rosa-l3":    $rosa-l3,
    "ev":         $ev
  ),
  $colors
);

$theme-colors: map-merge(
  (
    "nero":       $nero,
    "rosa-l1":    $rosa-l1,
    "rosa-l2":    $rosa-l2,
    "rosa-l3":    $rosa-l3,
    "ev":         $ev
  ),
  $theme-colors
);

$spacers: map-merge(
  (
    6:  ($spacer * 3.25),
    7:  ($spacer * 3.5),
    8:  ($spacer * 4),
    9:  ($spacer * 4.5),
    10: ($spacer * 9),
    20: ($spacer * 9.25)
  ),
  $spacers
);

$sizes: map-merge(
  (
    15px: 15px,
    20px: 20px,
    30px: 30px,
    40px: 40px,
    45px: 45px,
    50px: 50px,
    120px: 120px,
    20: 20%,
    33: 33%,
    40: 40%,
  ),
  $sizes
);
