.detail-shipment {
    .hour {
        cursor: pointer;
    }
    .shipment-choice {
        @media(max-width: 90em) {
            display: flex;
            align-items: baseline;
        }
    input {
        @media(max-width: 90em) {
            margin-right: .5em;
            }
        }
    }
}

.dayHourDetail {
    h3 {
        font-size: 1.2rem;
        margin-bottom: .5em;
    }
    .time {
        display: block;
    }
    @media(max-width: 47em) {
        width: 50% !important
    }
}


.shipment-detail-separator {
    margin: .2em 0 .8em 0;
}

.header-shipment {
    color: #000;
    font-size: 1.28571429em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-weight: 700;
}

.error_choice {
    padding: .5em;
    color: #fff;
    background-color: #db2828;
    font-size: 16px;
    border-radius: 5px;
    margin-bottom: .8em;
    margin-left: 1.1rem;
    width: fit-content;
}