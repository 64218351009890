/*-----------------------------------------------------------*/
/*cart*/
#conferma_order .box-riepilogo-ordini.pt-6{padding-top: 1rem}

.step-cart span{font-size: 0.8em;}
@media (min-width: 48em) {
  .step-cart span{font-size: 1em;}
}

.step-cart, .step-cart:hover, .step-cart:active, .step-cart:focus{
  color: rgba(0,0,0,1);
}
.step-cart.completed{
  color: rgba(0,0,0,0.4);
  font-weight: 400;
}
.step-cart.active{
  color: #d6548e;
  font-weight: 700;
}
.step-cart.completed>.icon:before {
  font-family: Step;
  content: '\e800';
}

.number-step-cart{
  background-color: #000;
  color: #fff;
}
.number-step-cart.completed{
  background-color: rgba(0,0,0,0.4);
}
.number-step-cart.active{
  background-color: #d6548e;
}

// order
.btn-pay.ui.button{width: 100%;}
.btn-pay.ui.button, .btn-pay.ui.button:hover, .btn-pay.ui.button:active, .btn-pay.ui.button:focus{
  background-color: #d6548e;
  color: #FFF;
}
@media (min-width: 36em) {
  .btn-pay.ui.button{width: auto;}
}
