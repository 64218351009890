/*-----------------------------------------------------------*/
/*home*/
.header-img-new{
  width: 100%;
  height: 90vh;
  min-height: 400px;
  max-height: 600px;
}

.header-text{
  top: 2rem;
  left: 1rem;
  & h2{font-size: 4em}
}

.logo-box-newsletter{height: 40px;}
.titolo-box-newsletter{font-size: 1.8em;}

.text-v{text-align: center;}

.img-block{
  height:40vh;
  min-height:300px;
  max-height: 500px;
}
