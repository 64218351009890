/*-----------------------------------------------------------*/
/*header*/
.header-home{
  height: 80vh;
  min-height: 800px;
  margin-left: 25%;
}

.text-header-home{
  width: 40%;
  & h1{line-height: 0.9em;}
}

.header-more.more::before{opacity: 0;}
.header-more.more{max-height: auto;}
