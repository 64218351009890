/*-----------------------------------------------------------*/
/*slide*/
.slide-product{
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.swiper-wrapper{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-slide-default{
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 61.9em) {
  .slide-product .swiper-slide{
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.swiper-pagination{
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}
.swiper-pagination span{
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba($black, 0.1);
  margin: 0 4px;
}
.swiper-pagination span.swiper-pagination-bullet-active{
  background-color: #CC5188;
}

.swiper-button-disabled{opacity: 0;@include transition(.4s ease);}

/*vertical*/
.swiper-container-vertical .swiper-wrapper {
  transition-property: -webkit-transform;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-container-vertical .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-container-vertical>.swiper-wrapper {-webkit-box-direction: normal;}
