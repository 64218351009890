/*-----------------------------------------------------------*/
/*nav*/
.navbar{background-color: rgba($rosa-l3, 0.2)}

#logo{margin-bottom: 0;}
#logo img{height: 100%}

.banner-mes{background-color: #F5DCDF;}
.move-text{white-space: nowrap;}
.move-text{
  animation: movedx 30s linear infinite;
}
@keyframes movedx {
  0% {transform: translateX(100vw);}
  100% {transform: translateX(-100%);}
}

// .top-nav{border-bottom: 2px solid #EFC8CA}

.hamburger-menu .line{top: calc(50% - 1px);}

.hamburger-menu .line,
.hamburger-menu .line::before,
.hamburger-menu .line::after{
  height: 2px;
  width: 20px;
  background-color: black;
  right: 0;
}
.hamburger-menu .line::before,
.hamburger-menu .line::after{
  content: "";
  position: absolute;
}
.hamburger-menu .line::before{top: -6px;}
.hamburger-menu .line::after{top: 6px;}

.hamburger-menu.active .line{background-color: rgba(black, 0);}
.hamburger-menu.active .line::before{
  transform: rotate(45deg);
}
.hamburger-menu.active .line::after{
  transform: rotate(-45deg);
}
.hamburger-menu.active .line::before, .hamburger-menu.active .line::after{top: 0}

.content-menu>div{margin-bottom: 4rem}
.content-menu>div>.item-menu{position: relative;}

.role_user{
  height: 10px;
  width: 10px;
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.02), 0 2px 4px 0 rgba(34,36,38,.05);
}

.voci-menu{
  z-index: 40;
  position: fixed;
  top: 80px;
  right: 0;
  width: 90%;
  visibility: hidden;
  transform: translateX(100%);
  background-color: white;
  box-shadow: 1px 0px 6px 0px rgba(0,0,0,0.05);
  height: calc(100% - 69px);
  & .content-menu {
    height: 100%;
    padding: 0.5rem 0 1rem 0;
    overflow: scroll;
  }
  & .content-menu .item-menu{
    border-bottom: 1px solid #EFC8CA;
    font-weight: 700;
    width: 100%;
  }
}
.voci-menu.show{
  visibility: visible;
  transform: translateX(0);
}
.voci-menu .content-menu{
  &::-webkit-scrollbar {width: 0;}
  &::-webkit-scrollbar-thumb {background: rgba(0,0,0,0);}
  &::-webkit-scrollbar-thumb:hover {background: rgba(0,0,0,0);}
}

.sot-cat {
  border-top: 1px solid #EFC8CA;
  //border-bottom: 1px solid #EFC8CA;
  transition: transform .2s ease-out .1s,opacity .3s ease-out .1s;
  // transform: translateY(-50px);
  position: absolute;
  text-align: left;
  width: 100%;
  // min-width: 768px;
  opacity: 0;
  visibility: hidden;
  // top: 42px;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: #FFFFFF;
  overflow: hidden;
  & .item-menu{
    display: block;
    border: hidden;
  }
}
.sot-cat.on{
  transform: translate(0);
  opacity: 1;
  height: auto;
  visibility: visible;
  position: relative;
}
.voci-menu .content-menu .sot-cat .item-menu{border: hidden;}

.open-sot-cat.active img{transform: rotate(180deg);}

// icone cta
.n-cart-item{
  bottom: -3px;
  height: 20px;
  width: 20px;
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.02), 0 2px 4px 0 rgba(34,36,38,.05);
}

// lingue
.ui.pointing.dropdown>.menu:after{
  left: auto;
  right: 1.4rem;
  background: $rosa-l2;
}

.ui.dropdown .menu{
  background: $rosa-l2;
  z-index: 80;
  border: hidden;
}
.ui.dropdown .menu>.item{
  font-weight: 700;
  border-bottom: 1px solid $rosa-l1
}

// popup cart
.ui.popup-cart {
  display: none;
  position: fixed;
  top: 80px;
  right: 1rem;
  left: 1rem;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  max-height: 80vh;
  overflow: scroll;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  background: #fff;
  padding: .833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  box-shadow: 0px 1px 6px -1px rgba(0,0,0,0.05);
  &::-webkit-scrollbar {width: 0;}
  &::-webkit-scrollbar-thumb {background: rgba(0,0,0,0);}
  &::-webkit-scrollbar-thumb:hover {background: rgba(0,0,0,0);}
}
@media (min-width: 36em) {
  .ui.popup-cart {
    left: auto;
    max-width: 320px;
  }
}
.ui.bottom.right.popup-cart{
  margin-right: 0;
}
.ui.bottom.right.popup-cart {
  transform-origin: right top;
}
.ui.large.popup-cart {
  font-size: 1.14285714rem;
}
.ui.visible.popup-cart {
  transform: translateZ(0);
  backface-visibility: hidden;
}
.ui.animating.popup-cart, .ui.visible.popup-cart {
  display: block;
}
.ui.bottom.popup-cart {
  margin: .71428571em 0 0;
}

// search
.navbar #searchDiv{top:82px;}
.navbar.affix #searchDiv{top:54px;}
#searchDiv{
  position: fixed;
  width: 100%;
  transform: translate(0, 40px);
  background-color: rgba(255,255,255,0.8);
}
#searchDiv.move{transform: translate(0, 0);}
