/*-----------------------------------------------------------*/
/*generale*/
body{
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 12pt;
  color: black;
  // hyphens: auto;
  width: 100vw;
  overflow-x: hidden;
  background-color: $rosa-l3;
  // background-color: red;
}
.text-body, .text-dark{
  &:hover, &:active, &:focus{
    color: black;
  }
}

.overflow-hidden{overflow: hidden;}
.cursor{cursor: pointer}
.opacity{opacity: 0;}
.opacity-2{opacity: 0.2;}
.acapo{white-space:nowrap;}

.button{position: relative;}

button.button:focus,
input:focus,
textarea:focus,
button:focus{
  outline: none;
}

.img-box{max-width: 120px}

/*text*/
a{
  display: inline-block;
  cursor:pointer;
  &:hover, &:active, &:focus {
    outline: inherit;
    text-decoration: none;
  }
}

.text-bold{font-weight: 700;}

.text-space{letter-spacing: 0.06em;}

.text-small{font-size: 0.8em;}
.text-default{font-size: 1em;}
.text-sm{font-size: 1.2em;}
.text-md{font-size: 1.4em;}

.font-special{font-family: 'La Belle Aurore', cursive;}

.text-white:hover, .text-white:active, .text-white:focus{color: $white;}

.line-small{line-height:0.8em;}

.titolo-highlight{
  display: inline;
  background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0) 50%, rgba($rosa-l2,1) 51%, rgba($rosa-l2,1) 80%, rgba($white, 0) 81%);
}

/*svg*/
svg{pointer-events: none;}

.bg-rosal3-5{background-color: rgba($rosa-l3,0.65);}
.bg-white-5{background-color: rgba($white,0.5);}

/*img*/
.cover {@include backgroundPositionCover;}
.contain {@include backgroundPositionContain;}

/*spazio*/
.pt-nav{padding-top: 82px;}

/*rotate*/
.rotate-180{transform: rotate(180deg);}
.rotate-90{transform: rotate(90deg);}
.rotate-m90{transform: rotate(-90deg);}
.rotate-m45{transform: rotate(-45deg);}

/*effetto*/
.effetto{transition: .4s ease;}

.sticky-top{
  position: sticky;
  top: 140px;
  z-index: 1;
}

/*affix*/
@media (max-width: 48em) {
  .affix{
    position: fixed;
    top: 0;
    background-color: $rosa-l3;
  }
  .affix-bottom{position: absolute;}
}

/*x*/
.x, .x:before{
  height: 2px;
  width: 20px;
  position: relative;
}
.x{
  transform: rotate(45deg);
  margin-bottom: 4px;
}
.x:before{
  content: " ";
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  left: 0;
}
.x-red, .x-red:before{background-color: #dc3545;}
.x-dark, .x-dark:before{background-color: #000;}

/*ombra*/
.ombra-b{box-shadow: 0px 1px 6px -1px rgba(0,0,0,0.05);}

/*glitter*/
// .bg-glitter{background-size: contain;}

/*box glitter*/
.center-box-r, .center-box-l{
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
}
.center-box-r, .center-box-l{width: 90%;}

.cover-top{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.cover-bottom{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.titolo-box{
  font-size: 2.4em;
  padding-top: 0.8rem;
}
.center-box-r .sub-box, .center-box-l .sub-box{text-align: center;}

.brt_logo {
  width: 40px;
}