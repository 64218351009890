/*-----------------------------------------------------------*/
/*z-index*/
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .top#{$infix}-#{$size}    {top: $length;}
        .right#{$infix}-#{$size}   {right: $length;}
        .bottom#{$infix}-#{$size} {bottom: $length;}
        .left#{$infix}-#{$size}   {left: $length;}

        .top#{$infix}-auto    {top: auto;}
        .right#{$infix}-auto   {right: auto;}
        .bottom#{$infix}-auto {bottom: auto;}
        .left#{$infix}-auto   {left: auto;}

        .z-index-#{$size} {z-index: $size;}
      }
    }
  }
}
