// @import '../../node_modules/bootstrap/scss/utilities.scss';
// @import '../../node_modules/bootstrap/scss/mixins.scss';

// svg
@mixin fill-variant($parent, $color) {
  #{$parent} {fill: $color;}
}

// KEYFRAMES MIXIN
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
$browser: '-webkit-' !global; @content; }
    @-moz-keyframes #{$animation-name} {
$browser: '-moz-' !global; @content; }
    @-ms-keyframes #{$animation-name} {
$browser: '-ms-' !global; @content; }
    @-o-keyframes #{$animation-name} {
$browser: '-o-' !global; @content; }
    @keyframes #{$animation-name} {
$browser: '' !global; @content; }
} $browser: null;

// USAGE
// @include keyframes('floating') {
//   0%{ #{$browser}transform: translateY(0%);}
//   50% { #{$browser}transform: translateY(8%);}
//   100% { #{$browser}transform: translateY(0%);}
// }



// ANIMATION MIXIN
@mixin animation($str...) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// USAGE
// @include animation(5s ease 0s normal none infinite running compass);


// ANIMATION DELAY MIXIN
@mixin animationDelay($args...) {
  -webkit-animation-delay: $args;
  -moz-animation-delay: $args;
  -ms-animation-delay: $args;
  -o-animation-delay: $args;
  animation-delay: $args;
}

// USAGE
// @include animationDelay(2s);


// TRANSITION MIXIN
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// USAGE
// @include transition(color .3s ease);


// TRANSFORM MIXIN
@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

// USAGE
// @include transform(rotate(20deg));


// TRANSFORMORIGIN MIXIN
@mixin transformOrigin($args...) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -ms-transform-origin: $args;
  -o-transform-origin: $args;
  transform-origin: $args;
}

// USAGE
// @include transformOrigin(50% 50%);


// TRANSLATEX MIXIN
@mixin translateX($val) {
  -webkit-transform: translateX($val);
  -moz-transform: translateX($val);
  -ms-transform: translateX($val);
  -o-transform: translateX($val);
  transform: translateX($val);
}

// USAGE
// @include translateX(-50%);


// TRANSLATEY MIXIN
@mixin translateY($val) {
  -webkit-transform: translateY($val);
  -moz-transform: translateY($val);
  -ms-transform: translateY($val);
  -o-transform: translateY($val);
  transform: translateY($val);
}

// USAGE
// @include translateY(-50%);


// PREFIX MIXIN
@mixin prefix($property, $value, $vendors: webkit moz ms o) {
      @if $vendors {
        @each $vendor in $vendors {
          #{"-" + $vendor + "-" + $property}: #{$value};
        }
      }
      #{$property}: #{$value};
}

// USAGE
// .element {
//   @include prefix(transform, rotate(42deg));
// }
//
// .element2 {
//   @include prefix(transform, rotate(42deg), webkit ms);
// }
//
// .element3 {
//   @include prefix(border-radius, 40px);
// }


// BOX-SIZING MIXIN
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

// USAGE
// @include box-sizing(border-box);


// ALWAYS CENTER % MIXIN
@mixin centered-tranform{
  position: absolute;
  left: 50%;
  top:50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// USAGE
// @include centered-tranform;


// ALWAYS CENTER MARGIN MIXIN
@mixin centered-margin($mtop:auto, $mleft:auto){
  position: absolute;
  left: 50%;
  top:50%;
  margin:$mtop auto auto $mleft;
}

// USAGE
// @include centered-margin(-53px, -140px);


// HARDWARE ACCELERATION MIXIN
@mixin hw-acceleration {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

// USAGE
// @include hw-acceleration ;



// BOX SHADOW COVER MIXIN
@mixin boxShadow($str...) {
  -webkit-box-shadow: #{$str};
  -moz-box-shadow: #{$str};
  -ms-box-shadow: #{$str};
  -o-box-shadow: #{$str};
  box-shadow: #{$str};
}

// USAGE
// @include boxShadow(0px 4px 0px 0px rgba(0,0,0,0.2));



// BACKGROUND COVER MIXIN
@mixin background($imgpath,$position:center center,$repeat: no-repeat, $size:cover) {
    background: {
        image: url($imgpath);
        position: $position;
        repeat: $repeat;
        size:$size;
    }
}

// USAGE
// @include background('../my/img/path.png');


// SHORTHAND BACKGROUND COVER MIXIN
@mixin backgroundShorthand($imgpath,$repeat: no-repeat,$position:center center, $size: cover) {
    background:url(#{$imgpath}) $repeat $position / $size;
}

// USAGE
// @include backgroundShorthand('../my/img/path.png');


// SHORTHAND BACKGROUND COVER
@mixin backgroundPositionCover($repeat: no-repeat,$position:center center, $size: cover) {
  background: {
      position: $position;
      repeat: $repeat;
      size:$size;
  }
}

// USAGE
// @include backgroundPositionCover;



// SHORTHAND BACKGROUND contain
@mixin backgroundPositionContain($repeat: no-repeat,$position:center center, $size: contain) {
  background: {
      position: $position;
      repeat: $repeat;
      size:$size;
  }
}

// USAGE
// @include backgroundPositionContain;


// FLEXBOX
@mixin flex{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
// USAGE
// @include flex;


// ROW
@mixin flex-row{
  flex-flow: row nowrap;
  -webkit-flex-flow: row nowrap;
}
// USAGE
// @include flex-row;

@mixin flex-row-wrap{
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
}
// USAGE
// @include flex-row-wrap;


// COLUMN
@mixin flex-column{
  flex-flow: column nowrap;
  -webkit-flex-flow: column nowrap;
}
// USAGE
// @include flex-column;

@mixin flex-column-wrap{
  flex-flow: column wrap;
  -webkit-flex-flow: column wrap;
}
// USAGE
// @include flex-column-wrap;


// JUSTIFY CONTENT
@mixin row-start{
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}
// USAGE
// @include row-start;

@mixin row-end{
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
// USAGE
// @include row-end;

@mixin row-center{
  justify-content: center;
  -webkit-justify-content: center;
}
// USAGE
// @include row-center;

@mixin row-spaceBetween{
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
// USAGE
// @include row-spaceBetween;

@mixin row-spaceAround{
  justify-content: space-around;
  -webkit-justify-content: space-around;
}
// USAGE
// @include row-spaceAround;


// ALIGN ITEMS
@mixin column-start{
  align-items: flex-start;
  -webkit-align-items: flex-start;
}
// USAGE
// @include column-start;

@mixin column-end{
  align-items: flex-end;
  -webkit-align-items: flex-end;
}
// USAGE
// @include column-end;

@mixin column-center{
  align-items: center;
  -webkit-align-items: center;
}
// USAGE
// @include column-center;

@mixin column-baseline{
  align-items: baseline;
  -webkit-align-items: baseline;
}
// USAGE
// @include column-baseline;

@mixin column-stretch{
  justify-content: stretch;
  -webkit-align-items: stretch;
}
// USAGE
// @include column-stretch;
