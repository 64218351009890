/*-----------------------------------------------------------*/
/*footer*/
.dropdown-footer{margin-bottom: 0.2rem;}

.cta-dropdown-footer{
  border: 1px solid $rosa-l1;
  padding: 1rem;
  position: relative;
  &::before{
    content: "+";
    font-size: 1.2em;
    line-height: 0.8em;
    position: absolute;
    top: 1rem;
    right: 1rem;
    transition: .4s ease;
  }
}
.cta-dropdown-footer.active::before{
  content: "-";
}
.content-dropdown-footer{
  visibility: hidden;
  overflow: hidden;
  height: 0;
  opacity: 0;
  border-left: 1px solid $rosa-l1;
  border-right: 1px solid $rosa-l1;
  border-bottom: 1px solid $rosa-l1;
  & a{display: block;}
}
.content-dropdown-footer.move{
  visibility: visible;
  height: auto;
  opacity: 1;
}
