/*-----------------------------------------------------------*/
/*product*/
//header
.header-more{
  max-height: 85px;
  overflow: hidden;
}
.header-more p{font-size: 0.88em;}
.header-more::before{
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  background: rgb(245,220,230);
  background: -moz-linear-gradient(0deg, rgba(245,220,230,1) 0%, rgba(245,220,230,0) 80%);
  background: -webkit-linear-gradient(0deg, rgba(245,220,230,1) 0%, rgba(245,220,230,0) 80%);
  background: linear-gradient(0deg, rgba(245,220,230,1) 0%, rgba(245,220,230,0) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5dce6",endColorstr="#f5dce6",GradientType=1);
}

// lista
#products{
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}
#products .card{
  width: 100%;
  padding: 0 0.5rem;
  margin-bottom: 2rem;
}

.card-body{padding: 0.5rem;}

.shortDescription-product{
  font-size: 0.85em;
  line-height: 1.4em;
}

.dot-more{
  height: 5px;
  width: 5px;
  background-color: rgba($ev, 0.5);
  border-radius: 50%;
  animation: poi 1s infinite;
  opacity: 0;
}
@keyframes poi {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}
.dot-more:nth-child(1){animation-delay: 0.2s}
.dot-more:nth-child(2){animation-delay: 0.4s}
.dot-more:nth-child(3){animation-delay: 0.6s}

// lista slide
.swiper-button-next,
.swiper-button-prev{
  top: 38%;
  transform: translateY(-50%);
}

// dettaglio
.box-gallery-product .gallery-top{width: 100%}

.info-text-product .nome-prodotto{font-size: 1.4em;}
.info-text-product #product-price,
.info-text-product #product-original-price,
.info-text-product #product-discount{font-size: 1.4em;}

.box-square-help{max-width: 360px}

.rif-prodotto{text-align: center;}

.info-text-product #sylius_add_to_cart_cartItem_quantity{
  width: 80px;
  border: 1px solid black;
  border-radius: 0;
  padding: 1rem;
  background-color: transparent;
  font-weight: 900;
  position: relative;
}

details[open] summary ~ * {
  animation: open 0.3s ease-in-out;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
details summary::-webkit-details-marker {
  display: none;
}

details summary {
  width: 100%;
  padding: 0.5rem 0;
  border-top: 1px solid rgba(0,0,0,0.1);
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 300;
  list-style: none;
}
details summary.summary-bg{
  padding: 1rem;
  border: hidden;
  background-color: rgba($white,0.5);
  margin: 0 0 0.25rem 0;
}

details summary:after {
  content: "+";
  color: black;
  position: absolute;
  font-size: 1.75rem;
  line-height: 0;
  margin-top: 0.75rem;
  right: 1rem;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}
details[open] summary:after {
  // transform: rotate(45deg);
  // font-size: 2rem;
  content: "-";
}
details summary {
  outline: 0;
}
details p {
  font-size: 0.9em;
  margin: 0 0 1rem;
  // padding-top: 1rem;
  line-height: 1.6em;
}

// button sort-by filtro
.cta-lista{
  background-color: #E9C9CB;
  padding: 0.6rem;
}

.menu-plugin-sort{
  top: 60px;
  left: 1rem;
  right: 1rem;
}
.menu-plugin-sort .item{
  width: 100%;
  padding: 1rem;
  color: #000;
  border-bottom: 1px solid #F5DCDF;
  font-weight: 700;
}

.menu-plugin-sort,
.menu-plugin-filter{
  opacity: 0;
  visibility: hidden;
  transition: .6s ease;
}
.menu-plugin-filter{
  transform: translateX(100%);
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  padding-top: 82px;
  background-color: #FFF;
  width: 100%;
}
.menu-plugin-sort.on,
.menu-plugin-filter.on{
  visibility: visible;
  opacity: 1;
  transition: .6s ease;
}
.menu-plugin-filter.on{
  transform: translateX(0);
}

.div-plugin-filter{
  height: 100%;
  overflow: scroll;
}

.content-bTn-plugin-filter{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: #FFF;
  border-top: 1px solid #dee2e6;
  padding: .5rem;
}

.sort-by .ui.menu{
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.3);
  box-shadow: none;
  position: relative;
}
.sort-by .ui.menu:after{display: none;}
.sort-by .ui.simple.dropdown{
  position: relative;
  width: 140px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.08em;
  font-size: 0.9em;
}
.sort-by .ui.menu .active.item{
  background-color: transparent;
}
.sort-by .ui.menu .dropdown.item .menu{
  width: 100%;
  border-radius: .28571429rem;
  margin-top: 4px;
}

// wishlist
.bTn-addTowishlist{background-color: rgba(255,255,255,0.5)}

.bitbag-wishlist-item img{width: 100%;}

// liste
.category-box{min-height: 100px;}
/* .category-box:first-child,
.category-box:last-child{
  width: 100%;
  background-color: #cccccc
} */
.category-box:nth-child(3n + 1){
  width: 100%;
  max-height: 400px;
  height: 40vh;
}
.category-box:nth-child(3n + 1) .center-box{
  top: 2rem;
  left: 1rem;
}
.category-box:nth-child(3n + 1) .center-box-bgGlitter{display: none;}

.center-box{max-width: 180px}

.category-box:nth-child(3n + 2), .category-box:nth-child(3n + 3){
  width: 75vw;
  height: 75vw;
}
.category-box:nth-child(3n + 3){margin-left: 25vw;}
.category-box:nth-child(3n + 2) .center-box,
.category-box:nth-child(3n + 3) .center-box{top: 2rem;}
.category-box:nth-child(3n + 2) .center-box{
  right: 0;
  transform: translateX(50%);
}
.category-box:nth-child(3n + 3) .center-box{
  left: 0;
  transform: translateX(-50%);
}
.category-box .center-box .center-box-bg{
  background-color: #F5DCDF;
  padding: 0.5rem 1rem;
}

// video
.bTn-video::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 50%;
  transform: scale(1.2);
}
video{outline: 0;}
.box-video-det{visibility: hidden;}
.box-video-det.on{visibility: visible;}

.fullscreen:-webkit-full-screen {
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;
}
.fullscreen:-webkit-full-screen video {
  height: 100%;
}
