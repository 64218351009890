/*-----------------------------------------------------------*/
/*border proporzionale*/
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}{ border: $border-width solid $border-color; }
    .border-top#{$infix}{ border-top: $border-width solid $border-color; }
    .border-right#{$infix}{ border-right: $border-width solid $border-color; }
    .border-bottom#{$infix}{ border-bottom: $border-width solid $border-color; }
    .border-left#{$infix}{ border-left: $border-width solid $border-color; }

    .border#{$infix}-0{ border: 0; }
    .border-top#{$infix}-0{ border-top: 0; }
    .border-right#{$infix}-0{ border-right: 0; }
    .border-bottom#{$infix}-0{ border-bottom: 0; }
    .border-left#{$infix}-0{ border-left: 0; }
  }
}

@each $color, $value in $theme-colors {
  .border-#{$color}-50 {
    border-color: rgba($value, 0.5);
  }
}
